export default class VideoPlayerManager {
  static markPlayed(url) {
    localStorage.setItem(`lh-video-player-manager-${url}`, true);
  }

  static shouldPlay(url) {
    const shouldPlay = localStorage.getItem(`lh-video-player-manager-${url}`);
    console.log("Should play:", !shouldPlay ?? true);

    return !shouldPlay ?? true;
  }
}
