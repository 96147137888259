<template>
  <div v-if="me && currentSession">
    <!-- Mobile layout -->
    <responsive-layout v-if="$vuetify.breakpoint.mobile">
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        light
      >
        <div class="main py-8 flex-fill">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="12"
                class="pl-0 d-flex align-start justify-start flex-column ml-1"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To theatre</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton :to="{ name: 'Theatre' }" class="ml-2"
                  >TO THEATRE</AppBackButton
                >
                <div class="route-name-background-container-information ml-2">
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="sessions-agenda-lobby-row"
            >
              <v-col cols="12">
                <v-responsive :aspect-ratio="16 / 9">
                  <iframe
                    allowfullscreen
                    :src="iframeSrc"
                    frameborder="0"
                    controls
                    style="height: 100%; width: 100%"
                  ></iframe>
                </v-responsive>
                <!--<v-row justify="center" align="center" class="mx-0 mb-8">
                  <v-col
                    cols="12"
                    class="
                      session-countdown-container
                      d-flex
                      align-center
                      justify-center
                    "
                  >
                    
                    <
                    <div class="mx-auto">
                      <lh-countdown
                        :time="countdownTime"
                        value-class="countdown-value"
                        content-class="white--text mt-n10"
                        :labels="{
                          days: 'Days',
                          hours: 'Hours',
                          minutes: 'Minutes',
                          seconds: 'Seconds',
                        }"
                      ></lh-countdown>
                    </div>
                    
                  </v-col>
                </v-row>-->

                <v-col
                  cols="12"
                  class="lighter-grey pa-0 mt-3"
                  v-if="event.state === 'LIVE'"
                >
                  <div
                    class="pl-2 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">ASK A QUESTION</h3>
                  </div>
                  <!-- Form -->
                  <div class="px-4 py-6">
                    <validation-observer
                      ref="qnaValidator"
                      #default="{ invalid }"
                    >
                      <v-form @submit.prevent="onQnaSubmit">
                        <v-container>
                          <v-row>
                            <!--<v-col cols="12" class="pl-2">
                              <validation-provider
                                #default="{ errors }"
                                name="name"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  hide-details="auto"
                                  v-model="qnaForm.name"
                                  label="Your Name"
                                  placeholder="Enter your Name"
                                  persistent-placeholder
                                  required
                                  :error-messages="errors"
                                >
                                </v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" class="pl-2">
                              <validation-provider
                                #default="{ errors }"
                                name="email"
                                rules="required|email"
                              >
                                <v-text-field
                                  type="email"
                                  outlined
                                  hide-details="auto"
                                  v-model="qnaForm.email"
                                  label="Your Email"
                                  placeholder="Enter your email"
                                  persistent-placeholder
                                  required
                                  :error-messages="errors"
                                >
                                </v-text-field>
                              </validation-provider>
                            </v-col>-->
                            <v-col cols="12" class="pl-2">
                              <validation-provider
                                #default="{ errors }"
                                name="your question"
                                rules="required"
                              >
                                <v-textarea
                                  outlined
                                  hide-details="auto"
                                  v-model="qnaForm.question"
                                  label="Your Question"
                                  placeholder="Enter a Question..."
                                  persistent-placeholder
                                  required
                                  :error-messages="errors"
                                >
                                </v-textarea>
                              </validation-provider>
                            </v-col>
                          </v-row>

                          <v-row justify="start" class="px-4 pl-2">
                            <v-col class="d-flex flex-column align-start px-0">
                              <v-btn
                                color="pink"
                                class="
                                  mr-4
                                  black--text
                                  font-weight-bold
                                  elevation-0
                                  rounded-0
                                  question-submit-btn
                                "
                                type="submit"
                                depressed
                                :loading="isQnaLoading"
                                :disabled="isQnaLoading"
                              >
                                SEND QUESTION
                              </v-btn>

                              <span v-if="isQnaSent" class="mt-6"
                                >Your question was sent!</span
                              >
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </validation-observer>
                  </div>
                  <!-- End form -->
                </v-col>

                <!-- Sessions in agenda section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">ABOUT THIS SESSION</h3>
                  </div>
                  <div class="py-6 d-flex white pl-1 pr-4">
                    <v-col cols="12">
                      <v-col
                        cols="12"
                        class="
                          agenda-container
                          d-flex
                          flex-row flex-wrap
                          white
                          pt-0
                          pb-0
                        "
                      >
                        {{ currentSession.details.about }}
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-col>

              <!-- Speaker bios section -->

              <v-col cols="12">
                <div
                  class="pl-1 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SPEAKER BIOS</h3>
                </div>
                <div class="py-6 d-flex white pr-4">
                  <v-col cols="12" class="pl-3 pr-3">
                    <v-col
                      cols="12"
                      v-for="(speaker, idx) in currentSession.speakers"
                      :key="idx"
                      class="agenda-container d-flex white pl-2 pt-1"
                    >
                      <v-col cols="12" sm="6" class="pl-0">
                        <v-img
                          :src="speaker.image"
                          style="max-width: 100%"
                        ></v-img>
                      </v-col>
                      <v-col cols="12" sm="6" class="text-left">
                        <p class="mt-2 information-speaker-name">
                          {{ speaker.name }}
                        </p>
                        <p class="mt-2 information-speaker-description">
                          {{ speaker.description }}
                        </p>
                        <v-divider class="lighter-grey-base"></v-divider>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout v-else>
      <v-sheet class="d-flex flex-column transparent" light>
        <div class="main">
          <v-container fluid style="margin-bottom: 150px">
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="8"
                class="pl-0 d-flex align-start justify-start ml-10"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To theatre</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton :to="{ name: 'Theatre' }" class="mt-2 ml-4"
                  >TO THEATRE</AppBackButton
                >
                <div
                  class="
                    route-name-background-container-meet-your-colleagues
                    mt-2
                  "
                >
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="sessions-agenda-lobby-row"
              style="max-height: 700px"
            >
              <v-col cols="8" class="d-flex custom-height-countdown">
                <v-responsive
                  :aspect-ratio="16 / 9"
                  min-height="700px"
                  max-height="700px"
                >
                  <iframe
                    allowfullscreen
                    :src="iframeSrc"
                    frameborder="0"
                    controls
                    style="height: 100%; width: 100%"
                  ></iframe>
                </v-responsive>
                <!--<v-col
                  cols="12"
                  class="
                    session-countdown-container
                    d-flex
                    align-center
                    justify-center
                  "
                >
                  <div class="mx-auto">
                    <lh-countdown
                      :time="countdownTime"
                      value-class="countdown-value countdown-value-session-page"
                      content-class="white--text mt-n10"
                      :labels="{
                        days: 'Days',
                        hours: 'Hours',
                        minutes: 'Minutes',
                        seconds: 'Seconds',
                      }"
                    ></lh-countdown>
                  </div>
                  
                </v-col>-->
              </v-col>

              <v-col cols="4" class="d-flex flex-column">
                <v-col
                  cols="12"
                  class="lighter-grey pa-0 mt-3"
                  v-if="event.state === 'LIVE'"
                >
                  <div
                    class="pl-2 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">ASK A QUESTION</h3>
                  </div>
                  <!-- Form -->
                  <div class="px-4 py-6">
                    <validation-observer
                      ref="qnaValidator"
                      #default="{ invalid }"
                    >
                      <v-form @submit.prevent="onQnaSubmit">
                        <v-container>
                          <v-row>
                            <!--<v-col cols="12" class="pl-2">
                              <validation-provider
                                #default="{ errors }"
                                name="name"
                                rules="required"
                              >
                                <v-text-field
                                  outlined
                                  hide-details="auto"
                                  v-model="qnaForm.name"
                                  label="Your Name"
                                  placeholder="Enter your Name"
                                  persistent-placeholder
                                  required
                                  :error-messages="errors"
                                >
                                </v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" class="pl-2">
                              <validation-provider
                                #default="{ errors }"
                                name="email"
                                rules="required|email"
                              >
                                <v-text-field
                                  type="email"
                                  outlined
                                  hide-details="auto"
                                  v-model="qnaForm.email"
                                  label="Your Email"
                                  placeholder="Enter your email"
                                  persistent-placeholder
                                  required
                                  :error-messages="errors"
                                >
                                </v-text-field>
                              </validation-provider>
                            </v-col>-->
                            <v-col cols="12" class="pl-2">
                              <validation-provider
                                #default="{ errors }"
                                name="your question"
                                rules="required"
                              >
                                <v-textarea
                                  outlined
                                  hide-details="auto"
                                  v-model="qnaForm.question"
                                  label="Your Question"
                                  placeholder="Enter a Question..."
                                  persistent-placeholder
                                  required
                                  :error-messages="errors"
                                >
                                </v-textarea>
                              </validation-provider>
                            </v-col>
                          </v-row>

                          <v-row justify="start" class="px-4 pl-2">
                            <v-col class="d-flex flex-column align-start px-0">
                              <v-btn
                                color="pink"
                                class="
                                  mr-4
                                  black--text
                                  font-weight-bold
                                  elevation-0
                                  rounded-0
                                  question-submit-btn
                                "
                                type="submit"
                                depressed
                                :loading="isQnaLoading"
                                :disabled="isQnaLoading"
                              >
                                SEND QUESTION
                              </v-btn>

                              <span v-if="isQnaSent" class="mt-6"
                                >Your question was sent!</span
                              >
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </validation-observer>
                  </div>
                  <!-- End form -->
                </v-col>
                <v-col cols="12" class="pb-0 pr-0 pl-0 pt-0">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">ABOUT THIS SESSION</h3>
                  </div>
                  <div class="py-6 d-flex white pl-1 pr-4">
                    <v-col cols="12">
                      <v-col
                        cols="12"
                        class="
                          agenda-container
                          d-flex
                          flex-row flex-wrap
                          white
                          pt-0
                          pb-0
                        "
                      >
                        {{ currentSession.details.about }}
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-row>

            <v-row
              justify="start"
              align="start"
              class="mt-12 sessions-agenda-lobby-row mt-16"
            >
              <!-- Speaker bios section -->

              <v-col cols="8">
                <div
                  class="pl-6 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SPEAKER BIOS</h3>
                </div>
                <div class="py-6 d-flex white pr-4">
                  <v-col cols="12" class="pl-8 pr-8 d-flex flex-row flex-wrap">
                    <v-col
                      cols="6"
                      v-for="(speaker, idx) in currentSession.speakers"
                      :key="idx"
                      class="
                        agenda-container
                        d-flex
                        white
                        pl-2
                        pt-1
                        flex-row flex-nowrap
                      "
                    >
                      <v-col cols="4" class="pl-0">
                        <v-img
                          :src="speaker.image"
                          style="max-width: 100%"
                        ></v-img>
                      </v-col>
                      <v-col cols="8" class="text-left">
                        <p class="mt-2 information-speaker-name">
                          {{ speaker.name }}
                        </p>
                        <p class="mt-2 information-speaker-description">
                          {{ speaker.description }}
                        </p>
                        <v-divider class="lighter-grey-base"></v-divider>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
import axios from "axios";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      iframeSrc: "https://streams.eventcdn.net/pandora/main-event",

      isQnaLoading: false,
      isQnaSent: false,
      qnaForm: {
        question: "",
      },
    };
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters(["me", "currentSession", "countdownTime"]),
    isValid() {
      return Object.values(this.form).every((field) => !!field);
    },
  },

  watch: {
    currentSession: {
      async handler() {
        // Track points for viewing a session
        if (this.currentSession) {
          console.log("Tracking session");
          axios.get(this.currentSession.tracking_url);
        } else {
          console.log("CurrentSession is not ready yet");
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async onQnaSubmit() {
      this.isQnaLoading = true;
      const data = {
        ...this.qnaForm,
        name: this.me.name,
        email: this.me.email,
      };

      const url =
        "https://streams.eventcdn.net/id/1cdcd5cf-5cf6-472e-8807-9513f187918a/qa/_SiGwdQHz-P71G7hYLB7DVvm3viDlmLuZ61H0fMgEIo";

      await axios.post(url, data);

      this.isQnaLoading = false;
      this.isQnaSent = true;

      setTimeout(() => (this.isQnaSent = false), 5000);

      this.form.question = "";

      this.$refs.qnaValidator.reset();
    },
  },
};
</script>

<style scoped>
.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background-image: url("https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}

.v-application.livehouse-app .countdown-value {
  font-size: 5rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}

.countdown-with-pink-text {
  color: var(--pink) !important;
  font: var(--unnamed-font-style-normal) normal bold 80px/60px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 80px/60px Pan Text;
}

.countdown-headline {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/32px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 22px/32px Pan Text;
}

.unit {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-22) var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--darker-grey);
  text-align: center;
  font: normal normal normal 18px/22px Pan Text;
}

.countdown-container {
  margin-top: 10.5rem;
}
</style>
