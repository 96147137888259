<template>
  <div
    style="height: 100%; width: 100%"
    :style="{
      'background-image': `url(${this.poster})`,
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
    }"
  >
    <!-- Video background-->

    <video
      ref="backgroundVideo"
      v-bind="videoProps"
      :poster="poster"
      preload="metadata"
      style="
        object-fit: cover;
        width: 1920px;
        height: 1080px;
        position: fixed;
        top: 0;
        left: 0;
      "
      v-if="backgroundVideoInitial || backgroundVideoLoop"
      @ended="onBackgroundVideoEnd"
      :src="backgroundVideoSrc"
    ></video>

    <!-- End Video background -->
    <v-container fluid v-if="isAuthenticated">
      <v-row style="z-index: 10; position: relative">
        <v-app-bar
          class="d-flex flex-column justify-center elevation-0 main-navigation"
        >
          <!-- Logo start -->
          <v-col
            sm="6"
            md="4"
            lg="8"
            cols="6"
            :style="!$vuetify.breakpoint.mobile ? 'height: 100%;' : ''"
            :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''"
            class="d-flex align-center"
          >
            <v-toolbar-title>
              <router-link to="/lobby">
                <v-img
                  :class="
                    !$vuetify.breakpoint.mobile
                      ? 'mx-auto ml-6 mb-3'
                      : 'mx-auto'
                  "
                  src="https://vod.eventcdn.net/pandora/smc2021/Logo%402x.png"
                  style="max-width: 100%"
                  :style="!$vuetify.breakpoint.mobile ? 'width: 385px;' : ''"
                ></v-img> </router-link
            ></v-toolbar-title>
          </v-col>
          <!-- Logo end -->

          <!-- Navigation links - start -->
          <v-col
            class="d-flex justify-end align-center navigation-icon-column"
            sm="6"
            md="8"
            lg="4"
            cols="6"
            style="height: 100%"
          >
            <!-- Mobile my-profile button -->
            <v-col
              v-if="$vuetify.breakpoint.mobile"
              cols="4"
              xs="4"
              sm="2"
              md="1"
            >
              <v-btn
                color="black"
                class="ma-2 white--text mobile-navigation-button"
              >
                <router-link
                  class="white--text navigation-text"
                  to="/my-profile"
                  >My profile</router-link
                >
                <v-icon
                  :right="!$vuetify.breakpoint.mobile ? true : false"
                  dark
                  color="pink"
                >
                  fas fa-user
                </v-icon>
              </v-btn>
            </v-col>

            <!-- Desktop my profile button -->
            <v-btn
              color="black"
              class="ma-2 white--text"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <router-link
                v-if="!$vuetify.breakpoint.mobile"
                class="white--text navigation-text"
                to="/my-profile"
                >My profile</router-link
              >
              <v-icon
                :right="!$vuetify.breakpoint.mobile ? true : false"
                dark
                color="pink"
              >
                fas fa-user
              </v-icon>
            </v-btn>

            <!-- Mobile menu button -->

            <v-col
              v-if="$vuetify.breakpoint.mobile"
              cols="4"
              xs="4"
              sm="2"
              md="1"
            >
              <v-btn
                color="black"
                class="ma-2 white--text mobile-navigation-button"
                @click="toggleOverlay()"
              >
                <span class="navigation-text" v-if="!$vuetify.breakpoint.mobile"
                  >Menu</span
                >
                <v-icon
                  :right="!$vuetify.breakpoint.mobile ? true : false"
                  dark
                  color="pink"
                >
                  fas fa-bars
                </v-icon>
              </v-btn>
            </v-col>

            <!-- Desktop menu button -->

            <v-btn
              color="black"
              class="ma-2 white--text"
              @click="toggleOverlay()"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <span class="navigation-text" v-if="!$vuetify.breakpoint.mobile"
                >Menu</span
              >
              <v-icon
                :right="!$vuetify.breakpoint.mobile ? true : false"
                dark
                color="pink"
              >
                fas fa-bars
              </v-icon>
            </v-btn>

            <!-- Mobile chat button -->

            <v-col
              v-if="$vuetify.breakpoint.mobile"
              cols="4"
              xs="4"
              sm="2"
              md="1"
            >
              <v-btn
                class="ma-2 white--text mobile-navigation-button"
                color="black"
              >
                <v-icon dark color="pink"> fas fa-comments </v-icon>
              </v-btn>
            </v-col>

            <AppChat v-if="!$vuetify.breakpoint.mobile && isAuthenticated">
              <template #activator="{ on, unreadMessages }">
                <v-btn class="ma-2 white--text" color="black" v-on="on">
                  <v-icon dark color="pink"> fas fa-comments </v-icon>
                </v-btn>
                <v-chip
                  color="red"
                  text-color="white"
                  class="notification-chip"
                  v-if="unreadMessages"
                >
                  !
                </v-chip>
              </template>
            </AppChat>

            <!-- <v-menu left bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="n in 5" :key="n" @click="() => {}">
                  <v-list-item-title>Option {{ n }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-col>
          <!-- Navigation links - end -->
        </v-app-bar>
      </v-row>
    </v-container>

    <!-- Fullscreen overlay menu - start -->
    <v-overlay
      ref="overlay"
      :z-index="zIndex"
      :value="overlay"
      opacity="1"
      class="menu-fullscreen-overlay"
    >
      <v-container fluid class="d-flex flex-column justify-center">
        <v-row>
          <v-col cols="12">
            <v-icon
              dark
              color="pink"
              @click="toggleOverlay()"
              class="fullscreen-overlay-close"
            >
              fas fa-times-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="router-links-container">
          <v-col cols="12" class="pa-0">
            <router-link class="white--text fullscreen-menu-links" to="/lobby"
              >Lobby</router-link
            >
          </v-col>
          <v-col cols="12" class="pa-0">
            <router-link class="white--text fullscreen-menu-links" to="/theatre"
              >THEATRE</router-link
            >
          </v-col>
          <v-col cols="12" class="pa-0">
            <router-link class="white--text fullscreen-menu-links" to="/lounge"
              >LOUNGE</router-link
            >
          </v-col>
          <v-col cols="12" class="pa-0">
            <router-link
              class="white--text fullscreen-menu-links"
              to="/meet-your-colleagues"
              >MEET YOUR COLLEAGUES</router-link
            >
          </v-col>
          <v-col cols="12" class="pa-0">
            <router-link
              class="white--text fullscreen-menu-links"
              to="/information"
              >INFORMATION</router-link
            >
          </v-col>
          <v-col cols="12" class="pa-0 mb-lg-5">
            <router-link
              class="white--text fullscreen-menu-links"
              to="/tour-de-pandora"
              >TOUR DE PANDORA</router-link
            >
          </v-col>
          <v-col
            cols="12"
            class="pa-0 mt-5 mb-lg-5"
            style="justify-content: center; align-items: center; display: flex"
          >
            <hr class="first-logo-seperator" />
            <v-img
              class="mx-auto pandora-fullscreen-logo"
              src="https://vod.eventcdn.net/pandora/smc2021/Pandora_Monogram.svg"
            ></v-img>
            <hr class="second-logo-seperator" />
          </v-col>
          <v-col cols="12" class="pa-0 mt-6 mb-lg-2 mb-5">
            <router-link
              class="white--text fullscreen-menu-smaller-links"
              to="/my-profile"
              >MY PROFILE</router-link
            >
          </v-col>
          <v-col cols="12" class="pa-0">
            <a
              class="white--text fullscreen-menu-smaller-links"
              @click="logOut()"
              >LOGOUT</a
            >
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
    <!-- Fullscreen overlay menu - end -->

    <slot
      v-bind="{
        toggleOverlay,
        replayBackgroundVideo,
        isLooping: videoProps.loop,
      }"
    ></slot>
  </div>
</template>

<script>
import AppChat from "@/components/Chat.vue";
import { mapGetters, mapState } from "vuex";
import VideoPlayerManager from "@/services/VideoPlayerManager";
export default {
  components: {
    AppChat,
  },

  props: {
    "background-video-initial": {
      type: String,
      required: false,
    },
    "background-video-loop": {
      type: String,
      required: false,
    },
    poster: {
      type: String,
      required: false,
    },
  },

  mounted() {
    this.scalePage();
    this.setStyles();
    window.addEventListener("resize", this.scalePage);

    const playInitial = VideoPlayerManager.shouldPlay(
      this.backgroundVideoInitial
    );

    if (!playInitial && this.backgroundVideoSrc) {
      this.onBackgroundVideoEnd();
    }
  },

  beforeDestroy() {
    this.unsetStyles();
    window.removeEventListener("resize", this.scalePage);
  },

  data() {
    return {
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      overlay: false,
      zIndex: 99999999,

      videoProps: {
        loop: false,
        muted: false,
        autoplay: true,
        plasinline: true,
      },
    };
  },

  computed: {
    ...mapState(["isChatOpen"]),
    ...mapGetters(["isAuthenticated"]),
    backgroundVideoSrc() {
      if (this.videoProps.loop && this.backgroundVideoLoop) {
        return this.backgroundVideoLoop;
      }

      return this.backgroundVideoInitial || this.backgroundVideoLoop;
    },
  },

  watch: {
    overlay: function (overlay) {
      if (overlay === true) {
        const body = document.body;
        const html = document.documentElement;
        body.style.overflow = "hidden";
        html.style.overflow = "hidden";
      } else {
        const body = document.body;
        const html = document.documentElement;
        body.style.overflow = "visible";
        html.style.overflow = "visible";
      }
    },
  },

  methods: {
    scalePage: function scalePage() {
      const defaultHeight = 1080;
      const defaultWidth = 1920;
      const app = document.getElementsByClassName("v-application--wrap")[0];
      const heightScale =
        Math.round((document.body.clientHeight / defaultHeight) * 1000) / 1000;
      const widthScale =
        Math.round((document.body.clientWidth / defaultWidth) * 1000) / 1000;

      this.scale = heightScale > widthScale ? widthScale : heightScale;

      app.style.transform = "scale(" + this.scale + ")";

      console.log(
        "Default width:",
        defaultWidth,
        "clientWidth:",
        document.body.clientWidth
      );

      app.style.transformOrigin =
        document.body.clientWidth >= defaultWidth ? "top center" : "top left";
    },
    setStyles() {
      // Body styles
      document.body.style.overflow = "hidden";

      // HTML Styles
      document.documentElement.style.overflow = "hidden";

      // App wrapper styles
      const app = document.getElementsByClassName("v-application--wrap")[0];
      app.style.height = "1080px";
      app.style.width = "1920px";
      app.style.display = "block";
      app.style.margin = "auto";
      //app.style.transformOrigin = "top center";
      app.style.position = "relative";
      //app.style.overflow = "hidden";

      // Main styles
      const main = app.getElementsByClassName("main")[0];
      main.style.height = "1000px";
      main.style.width = "1920px";
      main.style.overflowY = "auto";
      main.style.overflowX = "hidden";
    },
    unsetStyles() {
      // HTML Styles
      document.documentElement.style.overflow = "auto";

      // Body styles
      document.body.style.overflow = "unset";

      // App styles
      const app = document.getElementsByClassName("v-application--wrap")[0];
      app.style.transform = "unset";
      app.style.transformOrigin = "unset";
      app.style.height = "100%";
      app.style.width = "100%";
      app.style.margin = "unset";
      app.style.transformOrigin = "unset";

      // Main styles
      const main = app.getElementsByClassName("main")[0];
      main.style.height = "100%";
      main.style.width = "100%";
      main.style.overflowY = "auto";
    },

    toggleOverlay() {
      this.overlay = !this.overlay;
    },
    onBackgroundVideoEnd() {
      console.log("Video background has ended - switching to loop");
      VideoPlayerManager.markPlayed(this.backgroundVideoInitial);
      this.videoProps.loop = true;

      console.log("BackgroundVideo Element");
      this.$refs.backgroundVideo.load();

      try {
        this.$refs.backgroundVideo.play();
      } catch (err) {
        console.error("Background video playback error:", err);
      }
    },
    replayBackgroundVideo() {
      console.log("Replaying background video");
      this.videoProps.loop = false;
      this.$refs.backgroundVideo.load();
      this.$refs.backgroundVideo.play();
    },
    logOut() {
      this.$store.commit("CLEAR_USER_CREDENTIALS");
    },
  },
};
</script>

<style scoped>
.v-application--wrap {
  height: 1080px !important;
  width: 1920px !important;
  display: block;
  margin: auto;
  transform-origin: top left;
  position: relative;
}

/* .v-main {
  height: 1080px !important;
  width: 1920px !important;
}

.main-app {
  height: 1080px !important;
  width: 1920px !important;
} */

/* .v-application {
   height: 1080px !important;
  width: 1920px !important;
} */

.v-app-bar {
  min-height: 80px;
}

.v-toolbar__content {
  min-height: 80px;
}

.navigation-text {
  font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-20) /
    var(--unnamed-line-spacing-40) var(--unnamed-font-family-pan-text) !important;
  letter-spacing: var(--unnamed-character-spacing-0) !important;
  font: normal normal bold 20px/40px Pan Text !important;
  text-transform: uppercase !important;
}

.v-chip__content {
  justify-content: center;
  font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-18) /
    32px var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
  text-align: center;
  font: normal normal bold 18px/32px Pan Text;
}

.fullscreen-menu-smaller-links {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-20) / 30px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
  text-align: center;
  font: normal normal normal 20px/30px Pan Text;
  text-decoration: none !important;
  text-transform: uppercase !important;
}

.pandora-fullscreen-logo {
  width: 30px;
  height: 40px;
}

.mobile-navigation-button {
  min-width: 0px !important;
  max-width: 40px !important;
}

.background-video {
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
